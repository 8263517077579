@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --almost-clear: #D6D9AC;
    --green-yellow: #C7CA83;
    --platinum-yellow: #D7CD86;
    --pale-yellow: #E2D890;
    --pale-gold: #E6B14C;
    --deep-gold: #E8A016;
    --pale-salmon: #E9A372;
    --deep-pink: #CD2D50;
    --deep-salmon: #D3302B;
    --pale-ruby: #8E0811;
    --deep-violet: #3C030A;
    --deep-purple: #1C0508;
    --tawny: #892507;
}

.attachementWrapper {
    .delete {
        display: none;
    }

    &:hover .delete {
        display: block;
    }
}

.feature-disabled {
    background-color: #999;
}

.mdxeditor-popup-container {
    width: 0;
    height: 0;
    border: none;
    margin: 0;
}